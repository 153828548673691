export const STUDIES_CONTENT_TRANSITION_SPRING_PROPS = {
    from: {
        opacity: 0,
        transform: 'translate3d(-20px, 0, 0)'
    },
    enter: {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)'
    },
    leave: {
        opacity: 0
    }
};
