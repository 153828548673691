export const PROJECT_DIALOG_CONTENT_IMAGE_EDIT_LAYER_SPRING_PROPS = {
    from: {
        opacity: 0
    },
    enter: {
        opacity: 1
    },
    leave: {
        opacity: 0
    },
    unique: true
};
