export const DEFAULT_CARD_ORDER = [
    { type: 'basics', variant: 4 },
    { type: 'skills', variant: 0 },
    { type: 'dreamjob', variant: 4 },
    { type: 'projects', variant: 4 },
    { type: 'experiences', variant: 4 },
    { type: 'studies', variant: 4 },
    { type: 'gifs', variant: 0 },
    { type: 'interestedBy', variant: 3 },
    { type: 'language', variant: 4 },
    { type: 'soundtrack', variant: 4 }
];
